<script setup lang="ts">
useHead({
	bodyAttrs: {
		class: 'dark:bg-gray-950',
	},
});
</script>

<template>
	<UContainer class="flex flex-col gap-2 pt-4">
		<slot />
	</UContainer>
</template>
